const axios = require("axios").default;
const MASTER_URL = process.env.MASTER_URL;

export async function GetNotice() {
  try {
    const URL = MASTER_URL + "/api/v2/board?type=notice";
    const res = await axios.get(URL);
    if (res.data.data.length > 0) {
      let response = res.data.data.slice(0, 5);
      return response;
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function GetReviewData() {
  try {
    const URL = MASTER_URL + "/api/data/review";
    const res = await axios.get(URL);
    return await res.data;
  } catch (e) {
    return [];
  }
}
