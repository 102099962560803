import { atom } from "recoil";

export const cartCntState = atom({
  key: "cartCntState",
  default: 0,
});

export const quoteCntState = atom({
  key: "quoteCntState",
  default: 0,
});

export const estimateCntState = atom({
  key: "estimateCntState",
  default: 0,
});
